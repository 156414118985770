import { web3 } from "@project-serum/anchor";

export enum MarketplaceWallets {
  TENSOR = "4zdNGgAtFsW1cQgHqkiWyRsxaAgxrSRRynnuunxzjxue",
  MAGIC_EDEN = "MEisE1HzehtrDpAAT8PnLHjpSSkRYakotTuJRPjTpo8",
  MAGIC_EDEN_V2 = "M2mx93ekt1fmXSVkTrUL9xVFHkmME8HTUi5Cyc5aF7K",
  MAGIC_EDEN_V2A = "1BWutmTvYPwDtmw9abTkS4Ssr8no61spGAvW1X6NDix",
}

export function isMarketplaceWallet(walletAddress: web3.PublicKey) {
  return MARKETPLACE_WALLETS.includes(walletAddress.toString());
}

export const MARKETPLACE_WALLETS: string[] = [
  MarketplaceWallets.TENSOR,
  MarketplaceWallets.MAGIC_EDEN,
  MarketplaceWallets.MAGIC_EDEN_V2,
  MarketplaceWallets.MAGIC_EDEN_V2A,
];

export const MARKETPLACE_NAME_MAP: { [key in MarketplaceWallets]: string } = {
  "4zdNGgAtFsW1cQgHqkiWyRsxaAgxrSRRynnuunxzjxue": "Tensor",
  MEisE1HzehtrDpAAT8PnLHjpSSkRYakotTuJRPjTpo8: "Magic Eden",
  M2mx93ekt1fmXSVkTrUL9xVFHkmME8HTUi5Cyc5aF7K: "Magic Eden",
  "1BWutmTvYPwDtmw9abTkS4Ssr8no61spGAvW1X6NDix": "Magic Eden",
};

export const MARKETPLACE_LINK_BUILDER_MAP: {
  [key in MarketplaceWallets]: (tokenAddress: string) => string;
} = {
  "4zdNGgAtFsW1cQgHqkiWyRsxaAgxrSRRynnuunxzjxue": (tokenAddress: string) => {
    return `https://tensor.trade/item/${tokenAddress}`;
  },
  MEisE1HzehtrDpAAT8PnLHjpSSkRYakotTuJRPjTpo8: (tokenAddress: string) => {
    return `https://magiceden.io/item-details/${tokenAddress}`;
  },
  M2mx93ekt1fmXSVkTrUL9xVFHkmME8HTUi5Cyc5aF7K: (tokenAddress: string) => {
    return `https://magiceden.io/item-details/${tokenAddress}`;
  },
  "1BWutmTvYPwDtmw9abTkS4Ssr8no61spGAvW1X6NDix": (tokenAddress: string) => {
    return `https://magiceden.io/item-details/${tokenAddress}`;
  },
};
